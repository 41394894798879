import React from 'react';
// import contract from './assets/images/ContractMay2020.pdf';

class Adoptions extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="adoptions-container">
                <div className="adoptions content">
                    <div className="side">
                        <a href="https://forms.gle/WwFxTU3bxb6yc65k6" target="_blank" rel="noopener noreferrer" className="call-to-action small">
                            <span>Apply to Adopt</span>
                        </a>
                    </div>
                    <div className="main">
                        <h1>Adoptions</h1>
                        <p className="headline">
                            We are expecting that we will have puppies ready for forever homes January and Spring 2025!
                        </p>
                        <p><b>If you send us an application or email, and do not get a reply, please check your spam folder. As we ALWAYS reply! Unfortunately sometimes our replies do end up in peoples junk folders!</b></p>
                        <p>Please feel free to contact us if you would like to be considered for one of these puppies, or would like more information about the breeding(s).</p>
                        <h2>Adopting with LunaNova</h2>
                        <p className="headline">Please do not plan your visit with our dogs on the same day that you are visiting any other kennels.</p>
                        <p>All new owners will complete a breeder/buyer contract that outlines what you can expect from us as your breeder, and what we expect from you as an owner. In this contract, the health guarantee is comprehensively outlined.</p>
                        <p><b>In an effort to keep our environment germ-free and parasite-free, we ask that you do not plan your visit to our home to meet with our dogs within one week of visiting any other kennel.  We also ask if you have been to ANY places that are frequented by dogs (dog parks, pet stores, vets, dog training/boarding/daycare facilities etc), that you do not plan on visiting our home for at least one week after. Thank you!</b></p>
                        <p>Please be sure to <a href="https://www.pwdca.org/" target="_blank" rel="noopener noreferrer">do your research</a> about Portuguese Water Dogs. For further reading on concerns, see the <a href="http://pwdcc.org/new-puppy/" target="_blank" rel="noopener noreferrer">PWDCC Buyer Beware</a>.</p>
                        <p>View our <a href="https://forms.gle/WwFxTU3bxb6yc65k6" target="_blank" rel="noopener noreferrer">LunaNova Adoption Application</a>, or contact us!</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Adoptions;
